import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, useLocation} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import mockServer from './mock';
import appConfig from 'configs/app.config';
import './locales';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const environment = process.env.NODE_ENV;

const routesWithReCaptcha = ['/contato']

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({environment});
}

function ConditionalGoogleReCaptchaProvider({ children }) {
    const location = useLocation()
    const isReCaptchaEnabled = routesWithReCaptcha.includes(location.pathname)

    if (isReCaptchaEnabled) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaKey} language="pt-BR">
                {children}
            </GoogleReCaptchaProvider>
        )
    }

    return <>{children}</>
}

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <Theme>
                        <ConditionalGoogleReCaptchaProvider>
                            <Layout />
                        </ConditionalGoogleReCaptchaProvider>
                    </Theme>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
