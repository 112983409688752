import React, {lazy, memo, Suspense} from 'react';
import {Loading} from 'components/shared';
import useDirection from 'utils/hooks/useDirection';
import useLocale from 'utils/hooks/useLocale';

const AppLayout = lazy(() => import('./SimpleLayout'));

const Layout = () => {
    useDirection();
    useLocale();

    return (
        <Suspense
            fallback={
                <div className="flex h-[100vh] flex-auto flex-col">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    );
};

export default memo(Layout);
